import { HouseFeature } from "@/types/HouseDesignInterface";

export const HouseFeatures: HouseFeature[] = [
  { name: "Ducted air conditioning", isSelected: false },
  { name: "Walk in wardrobe", isSelected: false },
  { name: "Walk in pantry", isSelected: false },
  { name: "900mm appliances", isSelected: false },
  { name: "Study", isSelected: false },
  { name: "Bath", isSelected: false },
  { name: "Media room", isSelected: false },
  { name: "2700mm ceilings", isSelected: false },
];
