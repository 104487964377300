export enum ImagesHouseTypes {
  FACADES = "facades",
  MAIN_IMAGE = "mainImage",
  FLOORPLANS = "floorplans",
  LOUNGES_ROOMS = "lounges",
  KITCHENS = "kitchens",
  BATHROOMS = "bathrooms",
}

export enum ImageHouseLabels {
  FACADES = 'Facade Options',
  MAIN_IMAGE = 'Main image',
  FLOORPLANS = 'Floor plan',
  LOUNGES_ROOMS = 'Living',
  KITCHENS = 'Kitchen',
  BATHROOMS = 'Bathroom',
}
