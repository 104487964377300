import { ImagesHouseTypes } from "@/enums/BuilderHouseImages";

export const validImagesColorInputs = [
  ImagesHouseTypes.KITCHENS,
  ImagesHouseTypes.BATHROOMS,
];

export const validsImagesNameInputs = [];

export const validsMultipleImagesInputs = [
  ImagesHouseTypes.FACADES,
  ImagesHouseTypes.KITCHENS,
  ImagesHouseTypes.BATHROOMS,
  ImagesHouseTypes.LOUNGES_ROOMS,
];

export const validImagesDescriptionInputs = [ImagesHouseTypes.FLOORPLANS];
